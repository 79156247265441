<template>
  <div class="row">
    <template v-if="loaded">
      <select v-model="selectedOption" name="" id="">
        <option value="">-- {{ $t("message.showAll") }} --</option>
        <option
            :key="option" :value="option" v-for="option in options" class="text-sentence">{{ $t("message." + option) }}
        </option>
      </select>
      <template v-if="histograms.length">
        <template v-if="!activeHistogram">
          <div
              :class="ind > 1 ? 'col-md-4' : 'col-md-6'"
              class=" mb-gutter" v-for="(histogram,ind) in histogramData"
              v-bind:key="histogram">
            <histogram
                :labels="histogram.labels"
                :datasets="[histogram.datasets]">
            </histogram>
          </div>
        </template>
        <template v-else>
          <div class="col-md-12">
            <histogram
                :labels="histogramData.labels"
                :datasets="[histogramData.datasets]">
            </histogram>
          </div>
        </template>
      </template>
      <div v-else>
        <p class="text-sentence ">
          {{ $t('message.no_data_available') }}.
        </p>
      </div>
    </template>
    <div v-else class="position-relative h-100">
      <div class="position-absolute top-50 start-50 translate-middle container-spinner">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ $t("message.loading") }}...</span>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import Histogram from "@/views/components/charts/Histogram";
import moment from 'moment'

export default {
  components: {
    Histogram: Histogram
  },
  watch: {
    maxDate() {
      this.getHistograms()
    }
  },
  data() {
    return {
      selectedOption: '',
      loaded: false,
      histograms: [],
      options: []
    }
  },
  props: ['pop', 'maxDate', 'minDate'],
  computed: {
    activeHistogram() {
      const active = this.histograms.filter(histo => {
        return histo.subtype.toUpperCase() === this.selectedOption.toUpperCase()
      })
      if (active.length) {
        return active[0]
      }
      return false

    },
    histogramData() {
      if (this.histograms.length > 0) {
        if (this.activeHistogram) {
          return {
            datasets: {
              label: this.$t('message.' + this.activeHistogram.subtype),
              borderWidth: .5,
              backgroundColor: this.setOpacity(this.hexToRgb(this.getColorCode(this.getColorByVitamin(this.activeHistogram.subtype))), 0.85),
              borderColor: '#fff',
              categoryPercentage: 1.0,
              barPercentage: 1.0,
              data: this.activeHistogram.data.counts.map((datapoint, ind) => {
                return {
                  x: this.activeHistogram.data.mids[ind],
                  // x: ind,
                  y: datapoint
                }
              }),
            },
            labels: this.activeHistogram.data.breaks
          }
        } else {
          return this.histograms.map(histo => {
            return {
              datasets: {
                label: this.$t('message.' + histo.subtype),
                borderWidth: .5,
                backgroundColor: this.setOpacity(this.hexToRgb(this.getColorCode(this.getColorByVitamin(histo.subtype))), 0.85),
                // borderColor: this.getColorCode(this.getColorByVitamin(histo.subtype)),
                borderColor: '#fff',
                categoryPercentage: 1.0,
                barPercentage: 1.0,
                data: histo.data.counts.map((datapoint, ind) => {
                  return {
                    x: histo.data.mids[ind],
                    // x: ind,
                    y: datapoint
                  }
                }),
              },
              labels: histo.data.breaks
            }
          })
        }

      } else {
        return []
      }

    },
  },
  created(){
this.mitt.on("langChanged", () => this.handleLangChanged());
  },
  mounted() {
    this.getHistograms()
  },
  methods: {
    handleLangChanged(){
      console.log("test")
      this.getHistograms();
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr;
    },

    async getHistograms() {
      this.loaded = false;
      let q = '/analytics-items?'
      if (this.pop) {
        q = '/analytics-segments?segmentid=' + this.pop.id + '&'
      }
      let timeFilter = 'created_at_lte=' + this.maxDate
      if (moment(this.maxDate).isSame(moment(), 'day')) {
        timeFilter = 'isactive=true'
      }
      const get = await this.axios.get(q + 'type=histogram&' + timeFilter)
      this.histograms = get.data

      //group histograms per date
      //run below code for each date-grouped array of histograms

      let engInd = 0
      let wbInd = 0
      this.options = [];
      if (this.histograms.length) {
        this.histograms.forEach((hist, ind) => {
          if (hist.subtype.toLowerCase() === 'eng') {
            engInd = ind
          }
          if (hist.subtype.toLowerCase() === 'wb') {
            wbInd = ind
          }
        })
        this.array_move(this.histograms, engInd, 0)
        this.array_move(this.histograms, wbInd, 0)
      }
      this.histograms.forEach(histo => {
        // this.options.push(histo.subtype)
        // console.log(histo)
        // this.options.push(this.$t("message." + histo.subtype))
        this.options.push(histo.subtype)
        // console.log(this.options)
      })
      this.loaded = true;
    },


  }
}
</script>