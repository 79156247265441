<template>
  <div class="h-100">
    <div v-if="loaded" style="max-width: 100vw; overflow-x: auto">
      <div v-if="descriptives.length === 0">
        <p class="text-sentence p-3">{{ $t("message.no_data_available") }}.</p>
      </div>
      <table v-else class="table table-bordered mb-0" id="descriptives_table">
        <thead>
          <tr class="bgc-koraalrood">
            <th class="text-capitalize">
              {{ $t("message.total_users") }}:
              {{
                descriptives[0].data[descriptives[0].subtype]
                  ? descriptives[0].data[descriptives[0].subtype][0]
                  : ""
              }}
            </th>
            <template
              v-bind:key="col"
              v-for="col in descriptives[0].data.names"
            >
              <th
                class="bgc-koraalrood c-200 text-sentence d-table-cell"
                v-if="!excludedCols.includes(col)"
              >
                {{ col }}
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-bind:key="row" v-for="row in ew">
            <template v-bind:key="subrow" v-for="(subrow, name) in row.data">
              <tr
                class="mainrow"
                v-if="name !== 'names' && name !== row.subtype"
              >
                <td
                  :style="
                    'border-right-width: 5px;border-right-color:' +
                    getColorCode(getColorByVitamin(name))
                  "
                  class="text-start text-sentence d-table-cell"
                >
                  {{ $t("message." + name) }}
                </td>
                <template v-bind:key="col" v-for="(col, ind) in row.data.names">
                  <td v-if="!excludedCols.includes(col)">
                    <progress-chart
                      v-if="percentileCols.includes(col)"
                      style="height: 64px; width: 64px"
                      class="mx-auto heatmap-chart"
                      :pct="(subrow[ind] / 7) * 100"
                      :border-width="4"
                      :f-size="'sm'"
                      :color="getColorByVitamin(name)"
                    >
                    </progress-chart>
                    <span v-else>
                      {{ subrow[ind] }}
                    </span>
                  </td>
                </template>
              </tr>
            </template>
          </template>
          <template v-bind:key="row" v-for="row in descriptives">
            <tr class="mainrow">
              <td
                class="text-start"
                :style="
                  'border-right-width: 5px;cursor:pointer;border-right-color:' +
                  getColorCode(getColorByVitamin(row.subtype))
                "
                @click.prevent="row.collapsed = !row.collapsed"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <!--TODO: Better way to break words?-->
                  <div class="text-sentence">{{ $t("message." + row.subtype) }}</div>
                  <button
                    class="btn btn-link p-0"
                  >
                    <img
                      v-show="!row.collapsed"
                      src="@/assets/icons/icon-navigation-expand.svg"
                      alt=""
                    />
                    <img
                      v-show="row.collapsed"
                      style="transform: rotate(-90deg)"
                      src="@/assets/icons/icon-navigation-expand.svg"
                      alt=""
                    />
                  </button>
                </div>
              </td>

              <template v-bind:key="col" v-for="(col, ind) in row.data.names">
                <td v-if="!excludedCols.includes(col)">
                  <div v-if="row.data[row.subtype]">
                    <progress-chart
                      v-if="percentileCols.includes(col)"
                      style="height: 64px; width: 64px"
                      class="mx-auto heatmap-chart"
                      :hide-animation="true"
                      :pct="(row.data[row.subtype][ind] / 7) * 100"
                      :border-width="'4'"
                      :f-size="'sm'"
                      :color="getColorByVitamin(row.subtype)"
                    >
                    </progress-chart>
                    <span v-else>
                      {{
                        row.data[row.subtype] ? row.data[row.subtype][ind] : ""
                      }}
                    </span>
                  </div>
                </td>
              </template>
            </tr>
            <template v-if="!row.collapsed">
              <template v-bind:key="subrow" v-for="(subrow, name) in row.data">
                <tr
                  style="background-color: #f9f9f9"
                  class="subrow"
                  v-if="name !== 'names' && name !== row.subtype"
                >
                  <td
                    :style="
                      'border-right-width: 5px;border-right-color:' +
                      getColorCode(getColorByVitamin(row.subtype))
                    "
                    class="text-start"
                  >
                    {{ $t("message." + name) }}
                  </td>
                  <template
                    v-bind:key="col"
                    v-for="(col, ind) in row.data.names"
                  >
                    <td v-if="!excludedCols.includes(col)">
                      <progress-chart
                        v-if="percentileCols.includes(col)"
                        style="height: 64px; width: 64px"
                        class="mx-auto heatmap-chart"
                        :pct="(subrow[ind] / 7) * 100"
                        :border-width="4"
                        :f-size="'sm'"
                        :color="getColorByVitamin(row.subtype)"
                      >
                      </progress-chart>
                      <span v-else>
                        {{ subrow[ind] }}
                      </span>
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </template>
        </tbody>
      </table>
    </div>
    <div v-else class="position-relative h-100">
      <div
        class="
          position-absolute
          top-50
          start-50
          translate-middle
          container-spinner
        "
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ $t("message.loading") }}...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProgressChart from "@/views/components/ProgressChart";
import moment from "moment";

export default {
  components: { ProgressChart },
  props: ["pop", "maxDate"],
  watch: {
    maxDate() {
      this.getDescriptives();
    },
  },
  data() {
    return {
      loaded: false,
      excludedCols: [
        "nbr.val",
        "nbr.null",
        "nbr.na",
        "sum",
        "SE.mean",
        "CI.mean.0.95",
        "var",
        "skewness",
        "skew.2SE",
        "kurtosis",
        "coef.var",
        "kurt.2SE",
        "normtest.W",
        "normtest.p",
      ],
      percentileCols: ["min", "max", "range", "median", "mean", "std.dev"],
      descriptives: [],
      ew: [],
    };
  },
  methods: {
    async getDescriptives() {
      let q = "/analytics-items?";
      if (this.pop) {
        q = "/analytics-segments?segmentid=" + this.pop.id + "&";
      }
      this.loaded = false;
      let timeFilter = "created_at_lte=" + this.maxDate;
      if (moment(this.maxDate).isSame(moment(), "day")) {
        timeFilter = "isactive=true";
      }
      const getEw = await this.axios.get(
        q + "type=descriptives&subtype=ew&" + timeFilter
      );
      this.ew = getEw.data.map((desc) => {
        desc.collapsed = true;
        return desc;
      });

      const get = await this.axios.get(
        q + "type=descriptives&subtype_ne=ew&" + timeFilter
      );
      this.descriptives = get.data.map((desc) => {
        desc.collapsed = true;
        return desc;
      });
      this.loaded = true;
    },
  },
  mounted() {
    this.getDescriptives();
    
  },
};
</script>

<style lang="scss" scoped>
table {
  th {
    text-align: center;
    color: #fff;
    font-weight: 400;
    vertical-align: middle;
  }

  table-layout: fixed;

  td {
    //height: 96px;
  }

  td,
  th {
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    //width: 96px;
    //min-width: 96px;
    &:first-child {
      //vertical-align: bottom;
      //width: 200px;
      //min-width: 200px;
      //width: 50px;
      //min-width: 20%;
    }
  }
}

.team-info {
  padding-left: 20px;
  padding-bottom: 15px;
}

.heatmap-chart {
  height: 64px;
  width: 64px;
}

@media screen and (min-width: 1200px) {
  #descriptives_table {
    min-width: 100%;
    width: auto;

    .mainrow td:first-child {
      width: 160px;
    }
  }
}

@media screen and (max-width: 1199px) {
  #descriptives_table {
    width: auto;
  }
}
</style>