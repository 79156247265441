<template>
  <div class="h-100">
    <div v-if="loaded">
      <div v-if="descriptives.length === 0">
        <p class="text-sentence p-3">{{ $t("message.no_data_available") }}.</p>
      </div>
      <table
        class="
          table table-bordered table-analytics table-striped table-responsive
        "
        v-else
      >
        <thead>
          <tr class="bgc-koraalrood">
            <th
              style="user-select: none"
              class="cursor-pointer text-capitalize"
              @click="handleSort('type', sortDir === 'ASC' ? 'DESC' : 'ASC')"
            >
              {{ $t("message.factor") }}
              <button
                click.prevent
                v-show="sortType === 'type' && sortDir === 'DESC'"
                class="no-hover btn btn-link p-0"
              >
                <i class="text-white fas fa-fw fa-sort-down"></i>
              </button>
              <button
                click.prevent
                v-show="sortType === 'type' && sortDir === 'ASC'"
                class="no-hover btn btn-link p-0"
              >
                <i class="text-white fas fa-fw fa-sort-up"></i>
              </button>
              <button
                style="opacity: 0.35"
                click.prevent
                v-show="sortType !== 'type'"
                class="no-hover btn btn-link p-0"
              >
                <i class="fa-fw text-white fas fa-sort"></i>
              </button>
            </th>
            <template v-bind:key="col" v-for="col in descriptives">
              <th
                style="user-select: none"
                class="text-capitalize bgc-koraalrood cursor-pointer c-200"
                v-if="!excludedCols.includes(col)"
                @click="
                  handleSort(col.subtype, sortDir === 'ASC' ? 'DESC' : 'ASC')
                "
              >
                {{ col.subtype }}
                <button
                  click.prevent
                  v-show="sortType === col.subtype && sortDir === 'DESC'"
                  class="no-hover btn btn-link p-0"
                >
                  <i class="fa-fw text-white fas fa-sort-down"></i>
                </button>
                <button
                  click.prevent
                  v-show="sortType === col.subtype && sortDir === 'ASC'"
                  class="no-hover btn btn-link p-0"
                >
                  <i class="fa-fw text-white fas fa-sort-up"></i>
                </button>
                <button
                  style="opacity: 0.35"
                  click.prevent
                  v-show="sortType !== col.subtype"
                  class="no-hover btn btn-link p-0"
                >
                  <i class="fa-fw text-white fas fa-sort"></i>
                </button>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <!--      <template v-bind:key="row" v-for="(row,name) in descriptives[0].data">-->
          <!--        <tr class="mainrow" v-if="name !== 'names' && name !== row.subtype">-->
          <!--          <td :style="'border-right-width: 5px;border-right-color:'+getColorCode(getColorByVitamin(name))"-->
          <!--              class="text-start">-->
          <!--            {{ $t('message.' + name) }}-->
          <!--          </td>-->
          <!--          <td v-bind:key="desc" v-for="(desc) in descriptives">-->
          <!--            <span>-->
          <!--              {{ Math.round((desc.data[name] * 100) * 100) / 100 }}%-->
          <!--            </span>-->
          <!--          </td>-->
          <!--        </tr>-->
          <!--      </template>-->

          <tr :key="r" v-for="r in sortedRows">
            <td
              :key="c"
              v-for="(c, i) in r"
              class="text-capitalize"
              :class="i > 0 ? '' : 'text-start'"
            >
              <template v-if="i > 0">
                <span
                  class="
                    d-flex
                    align-items-baseline
                    flex-grow-1
                    text-end
                    monospace
                  "
                >
                  <span class="flex-grow-1 c-200 text-end">&nbsp;</span>
                  <span class="c-200">{{
                    (i > 0 ? c.toFixed(2) : c)
                      .toString()
                      .padStart(5, String.fromCharCode(160))
                  }}</span>
                  <span class="text-start flex-grow-1 text-sm">&nbsp;%</span>
                </span>
              </template>
              <template v-else>
                <span class="">{{ c }}</span>
              </template>

              <!--          <div class="d-flex align-items-baseline" :class="i > 0 ? 'monospace' : ''" >-->
              <!--            <span class="flex-grow-1">&nbsp;</span>-->
              <!--            <span class="text-end">{{ i>0 ? c.toFixed(2) : c }}</span>-->
              <!--            <span class="text-start flex-grow-1" v-if="i > 0">&nbsp;%</span>-->
              <!--          </div>-->

              <!--           <span class=" flex-grow-1 text-end c-200" :class="c > 0 ? 'c-groen' : 'c-koraalrood'">-->
              <!--                 {{ c }}-->
              <!--               </span>-->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-else class="position-relative h-100">
      <div
        class="
          position-absolute
          top-50
          start-50
          translate-middle
          container-spinner
        "
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ $t("message.loading") }}...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import ProgressChart from "@/views/components/ProgressChart";
// import Search from "@/views/components/simple/Search";
import moment from "moment";

const _ = require("lodash");

export default {
  // components: {Search},
  computed: {
    sortedRows() {
      const searched = this.tableRows.filter((r) => {
        return r[0].indexOf(this.search) > -1;
      });
      let sortType = 0;
      switch (this.sortType) {
        case "engagement":
          sortType = 1;
          break;
        case "wellbeing":
          sortType = 2;
          break;
        default:
          sortType = 0;
          break;
      }
      return _.orderBy(searched, [sortType], this.sortDir.toLowerCase());
    },
    tableRows() {
      const rows = [];
      if (this.descriptives.length > 1) {
        const data = this.descriptives[0].data;
        for (let n in data) {
          if (n !== "names" && n !== data[n].subtype) {
            const thisrow = [];
            thisrow.push(this.$t("message." + n));
            this.descriptives.forEach((d) => {
              let r = Math.round(d.data[n] * 100 * 100) / 100;
              let padded = r;
              // let padded = r.toFixed(2)
              // console.log('r',r,padded)
              thisrow.push(padded);
            });
            rows.push(thisrow);
          }
        }
      }
      return rows;
    },
  },
  watch: {
    maxDate() {
      this.getDescriptives();
    },
  },
  props: ["pop", "maxDate"],
  methods: {
    handleSearch(val) {
      this.search = val;
    },
    handleSort(type, dir = "ASC") {
      this.sortType = type;
      this.sortDir = dir;
    },
    async getDescriptives() {
      let q = "/analytics-items?";
      if (this.pop) {
        q = "/analytics-segments?segmentid=" + this.pop.id + "&";
      }
      this.loaded = false;
      let timeFilter = "created_at_lte=" + this.maxDate;
      if (moment(this.maxDate).isSame(moment(), "day")) {
        timeFilter = "isactive=true";
      }
      const get = await this.axios.get(q + "type=ria&" + timeFilter);
      this.descriptives = get.data;
      this.loaded = true;
    },
  },
  // components: {ProgressChart},
  data() {
    return {
      loaded: false,
      excludedCols: [
        "nbr.val",
        "nbr.null",
        "nbr.na",
        "sum",
        "SE.mean",
        "CI.mean.0.95",
        "var",
        "skewness",
        "skew.2SE",
        "kurtosis",
        "coef.var",
        "kurt.2SE",
        "normtest.W",
        "normtest.p",
      ],
      percentileCols: ["min", "max", "range", "median", "mean", "std.dev"],
      descriptives: [],
      ew: [],
      sortType: "type",
      sortDir: "ASC",
      search: "",
    };
  },

  mounted() {
    this.getDescriptives();
  },
};
</script>

<style lang="scss" scoped>
table {
  th {
    text-align: center;
    color: #fff;
    font-weight: 400;
    vertical-align: middle;
  }

  table-layout: fixed;

  td {
    //height: 96px;
  }

  td,
  th {
    text-align: center;
    overflow: hidden;
    vertical-align: middle;
    //width: 96px;
    //min-width: 96px;
    &:first-child {
      //vertical-align: bottom;
      //width: 200px;
      //min-width: 200px;
      //width: 50px;
      //min-width: 20%;
    }
  }
}

.team-info {
  padding-left: 20px;
  padding-bottom: 15px;
}

.heatmap-chart {
  height: 64px;
  width: 64px;
}
</style>