<template>
  <div>
    <!--    <pre>{{nodeData}}</pre>-->
    <div v-show="loaded">
      <div v-if="nodes.length">
        <div class="px-3 mt-3 mb-3">
          <div class="ms-auto btn-group" id="network-influence-buttons">
            <button
              @click.prevent="toggleDirection(true)"
              class="btn-sm btn btn-secondary"
              :class="!showInfluencedBy || 'active'"
            >
              <span>{{ $t("message.influenced_by") }}</span>
            </button>
            <button
              @click.prevent="toggleDirection(false)"
              class="btn-sm btn btn-secondary"
              :class="showInfluencedBy || 'active'"
            >
              <span>{{ $t("message.influences") }}</span>
            </button>
          </div>
        </div>

        <div style="max-height: 60vh" id="mynetwork"></div>
        <div class="mx-0 mb-3 px-3 mt-5 network-columns">
          <div
            style="break-inside: avoid; margin-bottom: 28px"
            class=""
            :key="group"
            v-for="group in groupedNodes"
          >
            <h5 class="text-capitalize">
              {{ showInfluencedBy ? group[0].from : group[0].to }}
            </h5>
            <table
              class="
                table-borderless table table-sm table-analytics table-responsive
                w-auto
              "
            >
              <tbody>
                <tr></tr>
                <tr :key="link" v-for="link in group">
                  <td class="w-25 monospace">
                    <span
                      class="d-flex align-items-baseline flex-grow-1 text-end"
                    >
                      <span
                        class="flex-grow-1 c-200 text-end"
                        :class="link.title > 0 ? 'c-groen' : 'c-koraalrood'"
                      >
                        <span v-if="link.title > 0">&nbsp;</span>
                        <span v-else> - </span>
                      </span>
                      <span
                        class="c-200"
                        :class="link.title > 0 ? 'c-groen' : 'c-koraalrood'"
                      >
                        {{ Math.abs(link.title * 100).toFixed(2) }}
                      </span>
                      <span class="text-start flex-grow-1 text-sm"></span>
                    </span>
                  </td>
                  <td>{{ showInfluencedBy ? link.to : link.from }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="text-sentence p-3">{{ $t("message.no_data_available") }}.</p>
      </div>
    </div>

    <div
      v-show="!loaded"
      class="position-relative h-100"
      style="min-height: 30vh"
    >
      <div
        class="
          position-absolute
          top-50
          start-50
          translate-middle
          container-spinner
        "
      >
        <div class="spinner-border" role="status">
          <span class="visually-hidden">{{ $t("message.loading") }}...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataSet } from "vis-data";
import { Network } from "vis-network";
import moment from "moment";
// import {DataSet} from 'vis-data/peer';
// import {Network} from 'vis-network/peer';
const _ = require("lodash");
export default {
  props: ["visible", "pop", "maxDate"],
  computed: {
    groupedNodes() {
      const groups = {};
      let key = "to";
      if (this.showInfluencedBy) {
        key = "from";
      }
      this.sortedRows.forEach((row) => {
        if (groups[row[key]]) {
          groups[row[key]].push(row);
        } else {
          groups[row[key]] = [row];
        }
      });
      const sorted = Object.values(groups).sort((a, b) => {
        return b.length - a.length;
      });
      return sorted;
    },
    sortedRows() {
      const searched = this.tableRows;
      const re = _.orderBy(searched, this.sortType, this.sortDir.toLowerCase());
      // console.log('sorted', re)
      return re;
    },
    tableRows() {
      return this.edges.map((edge) => {
        return {
          title: edge.title,
          from: this.nodes.find((node) => node.id === edge.from).label,
          amt: Math.round(edge.title * 100 * 100) / 100,
          to: this.nodes.find((node) => node.id === edge.to).label,
        };
      });
    },
  },
  created() {
    console.log("created network");
    this.mitt.on("langChanged", () => this.handleLangChanged());
  },
  watch: {
    maxDate() {
      this.initNetwork();
    },
  },
  methods: {
    handleLangChanged() {
      // console.log("lang emit");
      // console.log(this.network);
      this.initNetwork();
    },
    toggleDirection(influencedBy) {
      influencedBy
        ? (this.showInfluencedBy = true)
        : (this.showInfluencedBy = false);
      this.setEdges(this.nodeData);
      this.initNetwork(false);
    },
    handleSearch(val) {
      this.search = val;
    },
    handleSort(type, dir = "ASC") {
      // console.log('handlesort', type, dir)
      this.sortType = type;
      this.sortDir = dir;
    },
    setNodes(data) {
      return data[0].data.nodes.map((node) => {
        // console.log(node)
        
        // if(node.label == 'WB' || node.label == 'ENG'){
        //   node.color = this.getColorCode(this.getColorByVitamin(node.label.toLowerCase()));
        // }else{
        //   node.color = this.getColorCode(node.color);
        // }
        node.color = this.getColorCode(node.color);
        node.label = this.$t("message." + node.label);
        node.font = {
          size: 10,
          face: "Rubik",
          weight: "700",
        };
        return node;
      });
    },
    setEdges(data) {
      return data[0].data.edges.map((edge) => {
        if (edge.color.charAt(0) !== "#") {
          edge.color = this.getColorCode(edge.color);
        }
        edge.arrows = this.showInfluencedBy ? "to" : "from";
        // edge.label = Math.abs(Math.round(edge.title * 100 * 100) / 100).toFixed(
        //   2
        // );
        edge.value = Math.round(edge.value * 10);
        edge.font = {
          align: "middle",
          size: 8,
          face: "Nunito",
          color: edge.color,
          strokeWidth: 7,
        };
        return edge;
      });
    },
    async getNet() {
      this.loaded = false;
      let q = "/analytics-items?";
      if (this.pop) {
        q = "/analytics-segments?segmentid=" + this.pop.id + "&";
      }
      let timeFilter = "created_at_lte=" + this.maxDate;
      // if (moment(this.maxDate).isSame(moment(),'day')) {
      //   timeFilter = 'isactive=true'
      // }
      if (this.minDate) {
        timeFilter += "&created_at_gte" + this.minDate;
      }
      if (
        moment(this.maxDate).isSame(moment(), "day") &&
        (!this.minDate || moment(this.minDate).isSame(moment(), "day"))
      ) {
        timeFilter = "isactive=true";
      }
      const get = await this.axios.get(q + "subtype=network&" + timeFilter);
      if (get.data && get.data.length) {
        this.nodeData = get.data;
        this.nodes = this.setNodes(get.data);
        this.edges = this.setEdges(get.data);
      }
      this.loaded = true;
    },
    setHeight() {
      var container = this.$el.querySelector("#mynetwork");
      var parentheight = container.parentNode.offsetHeight;
      container.style.height = parentheight + "px";
      this.loaded = true;
    },
    async initNetwork(get = true) {
      if (get) {
        await this.getNet();
      }
      var nodes = new DataSet(this.nodes);
      var edges = new DataSet(this.edges);

      var container = this.$el.querySelector("#mynetwork");
      var data = {
        nodes: nodes,
        edges: edges,
      };

      var options = {
        layout: {
          randomSeed: "0.842391529954641:1628759950345",
        },
        physics: {
          repulsion: {
            centralGravity: 0.2,
            springLength: 200,
            springConstant: 0.02,
            nodeDistance: 50,
            damping: 1
          },
        minVelocity: 0.75,
        solver: "repulsion"
        },
        edges: {
          scaling: {
            label: false,
            min: 0,
            max: 10,
          },
        },

        nodes: {
          borderWidth: 0,
          borderWidthSelected: 3,
          shape: "dot",
        },
      };
      const network = new Network(container, data, options);
      this.network = network;
      this.setHeight();
      // console.log("seed", network.getSeed());
    },
  },
  mounted() {
    setTimeout(this.initNetwork, 100);
  },
  data() {
    return {
      nodeData: null,
      showInfluencedBy: false,
      sortType: "from",
      sortDir: "ASC",
      search: "",
      loaded: false,
      network: null,
      nodes: [],
      edges: [],
    };
  },
};
</script>

<style lang="scss">
.network-columns {
  column-count: 3;
}

@media all and (max-width: 1366px) {
  .network-columns {
    column-count: 2;
  }
}

@media all and (max-width: 767px) {
  .network-columns {
    column-count: 1;
  }

  #network-influence-buttons{
    display: flex;
  }
}
</style>