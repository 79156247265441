<template>
  <div class="chartwrapper bar">
    <!--    <pre>-->
    <!--&lt;!&ndash;      {{ chartData }}&ndash;&gt;-->
    <!--      {{ datasets }}-->
    <!--    </pre>-->
    <canvas id="chart"></canvas>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';

export default {
  mounted() {
    try {
      this.initChart()
    } catch (e) {
      console.error(e)
    }
  },
  data() {
    return {
      options: {

        animation: {
          duration: 0
        },
        scales:{
          x:{
            // min:1.5,
            min: 1,
            max: 7,
            type:'linear',
            offset:false,

            grid:{
              offset:false
            },
            ticks:{
              stepSize:1
            }
          },
          y:{
            ticks: {
              stepSize:  1,
              beginAtZero: true,
            }
          }
        }
        // scales: {
        //   xAxes: [
        //     { display: false, barPercentage: 1.25, ticks: { max: this.labels[this.labels.length - 2] }},
        //     { display: false, ticks: { autoSkip: false, max: this.labels[this.labels.length - 1]}},
        //     // { scaleLabel: { display: true, labelString: 'Range', fontFamily: 'Roboto', fontSize: 14 }}
        //   ],
        //   yAxes: [{
        //     ticks: {
        //       beginAtZero: true
        //     }
        //   }]
        // }
      },
      chart: null,
    }
  },
  computed: {
    config() {
      return {
        type: 'bar',
        data: {
          labels: this.labels,
          datasets: this.datasets
        },
        options: this.options
      }
    }
  },
  props: [
    'labels',
    'datasets',
    // 'options',
  ],
  watch: {
    datasets() {
      this.initChart()
      // this.initChart()
    }
  },
  methods: {
    initChart() {
      if (this.chart && this.chart.destroy) {
        this.chart.destroy()
      }
      this.chart = new Chart(
          this.$el.querySelector('canvas#chart'),
          this.config
      );
    }
  }
}
</script>